$color-primary: #eb000d;
$color-secondary: $color-primary;

$button-bg-color: $color-primary;
$button-text-color: #ffffff;

$heading-decoration-color: #000000;

$footer-bg-color: #000000;
$footer-text-color: #ffffff;
$footer-site-color: $color-primary;
$footer-divider-color: #323232;

$service-block-color-1: #eb000d;
$service-block-color-2: #000000;
$service-block-color-3: #fcd108;
$service-block-color-4: #0e680e;
$service-block-color-5: #dd6625;
$service-block-color-6: #362577;


@import "/assets/styles/layout";

header nav li.active {

  .decoration-helper {
    color: $color-primary;
    box-sizing: border-box;
    display: block;

    width: 16px;
    height: 8px;
    margin-left: -8px;
    bottom: -12px;
    @media (min-width: theme('screens.lg')) {
      width: 22px;
      height: 11px;
      margin-left: -11px;
      bottom: -18px;
    }

    overflow: hidden;
    position: absolute;
    left: 50%;
  }

  .decoration-helper::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    @apply border-b-1 lg:border-b-2;
    @apply border-r-1 lg:border-r-2;
    border-color: $color-primary;
    transform: rotate(45deg);
    width: 11.31px;
    height: 11.31px;
    left: 2.3px;
    top: -5.7px;
    @media (min-width: theme('screens.lg')) {
      width: 15.56px;
      height: 15.56px;
      left: 3.3px;
      top: -7.7px;
    }

  }

}

.hero {

  background-position: -190px center;
  @apply md:bg-center;

  @media (min-width: theme('screens.md')) {
    background-image: url("../images/bg-hero.jpg");
  }
}

.home-boxes {

  .block-service {
    @apply shadow-2xl border-t-10;

    @apply bg-no-repeat bg-right-bottom;

    &.block-service-1 {
      .block-icon {
        background-image: url("../images/icon-waste.png");
      }

      background-image: url("../images/bg-waste.png");
    }

    &.block-service-2 {
      .block-icon {
        background-image: url("../images/icon-projects.png");
      }

      background-image: url("../images/bg-projects.png");
    }

    &.block-service-3 {
      .block-icon {
        background-image: url("../images/icon-separation.png");
      }

      background-image: url("../images/bg-separation.png");
    }

    &.block-service-4 {
      .block-icon {
        background-image: url("../images/icon-recycling.png");
      }

      background-image: url("../images/bg-recycling.png");
    }

    &.block-service-5 {
      .block-icon {
        background-image: url("../images/icon-technologies.png");
      }

      background-image: url("../images/bg-technologies.png");
    }

    &.block-service-6 {
      .block-icon {
        background-image: url("../images/icon-activities.png");
      }

      background-image: url("../images/bg-activities.png");
    }

  }

}

.about-us {

  background-position: -380px center;
  @apply md:bg-center;

  @media (min-width: theme('screens.md')) {
    background-image: url("../images/bg-about-us.jpg");
  }

  .about-us-content-container {
    @apply xl:w-148;
  }
}