// @see https://tailwindcss.com/docs/functions-and-directives#screen
// @config "./tailwind.site.config.js";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "/assets/styles/fonts/roboto";
@import "/assets/styles/fonts/roboto-condensed";
@import "/assets/styles/fonts/font-awesome";

/**
 * Default variables
 */
$color-primary: #0000ff !default;
$color-secondary: #00ff00 !default;

$button-bg-color: $color-primary !default;
$button-text-color: #ffffff !default;

$heading-decoration-color: #000000 !default;

$footer-bg-color: #000000 !default;
$footer-text-color: #ffffff !default;
$footer-site-color: $color-primary !default;
$footer-divider-color: #323232 !default;

$service-block-color-1: #eb000d !default;
$service-block-color-2: #000000 !default;
$service-block-color-3: #fcd108 !default;
$service-block-color-4: #0e680e !default;
$service-block-color-5: #dd6625 !default;
$service-block-color-6: #362577 !default;

$font-family: 'Roboto Condensed' !default;
$font-weight-light: 300 !default;
$font-weight-semibold: 500 !default;
$font-weight-bold: 700 !default;
$font-weight-black: 900 !default;

$navigation-font-family: $font-family !default;
$navigation-font-weight: $font-weight-semibold !default;

body {
  font-family: $font-family;
}

.button-xl {
  background-color: $button-bg-color;
  color: $button-text-color;
  @apply inline-block font-semibold py-2 xl:py-3 px-5 lg:px-7 xl:px-9 text-base lg:text-lg xl:text-xl ;
}

.button-base {
  background-color: $button-bg-color;
  color: $button-text-color;
  @apply inline-block py-2 px-5 text-base font-semibold;
}

.prose {
  @apply max-w-none text-black;

  h2 {
    @apply text-xl lg:text-2xl;
  }

  a {
    color: $color-primary;
  }

  p {
    @apply text-justify;
  }

}

.body-container {
  @apply mx-auto max-w-hd;
}

.page-container {
  @apply container mx-auto xl:max-w-6xl px-3;
}

header {
  @apply flex justify-between items-center h-20 lg:h-26 xl:h-28;

  .logo {
    img {
      @apply w-auto h-8 lg:h-13 xl:h-14;
    }
  }

  nav {
    @apply hidden md:block;

    & > ul {

      @apply flex flex-row space-x-6 lg:space-x-10 xl:space-x-11;

      & > li {

        position: relative;

        &.active > a, &.current_ancestor > a, & a:hover {
          color: $color-primary;
        }

        & > a {
          @apply text-base lg:text-lg xl:text-xl;
          font-family: $navigation-font-family;
          font-weight: $navigation-font-weight;

          .submenu-helper {
            &:before {
              content: "\f078";
              //noinspection CssNoGenericFontName
              font-family: "Font Awesome 6 Free";
              font-weight: 700;
              @apply text-xs;
            }
          }

        }

        & > ul {

          @apply -left-5;

          ul {
            @apply left-full -top-2;
          }
        }

        ul {
          @apply z-50;
          @apply hidden;
          @apply absolute;
          @apply bg-white py-2 shadow rounded;

          & > li {
            @apply relative;
            @apply whitespace-nowrap py-1 pl-5 pr-10;

            &.active > a, &.current_ancestor > a, & a:hover {
              color: $color-primary;
            }

            a {
              @apply flex items-center;

              .navigation-label {
                @apply flex-grow;
              }

              .submenu-helper {
                @apply flex-none w-2 -mr-5;

                &:before {
                  @apply block text-right;
                  content: "\f054";
                  //noinspection CssNoGenericFontName
                  font-family: "Font Awesome 6 Free";
                  font-weight: 700;
                  @apply text-xs text-right;
                }
              }
            }
          }
        }

        &:hover > ul {
          @apply block;
        }

        & > ul > li:hover ul {
          @apply block;
        }

      }

    }

  }

  .mobile-menu-button-container {
    @apply flex md:hidden;

    .mobile-menu-button {
      @apply inline-flex items-center justify-center p-2 hover:text-white;
      color: $color-primary;

      &:hover {
        background-color: $color-primary;
      }

      .mobile-menu-close-icon,
      .mobile-menu-open-icon {
        @apply h-6 w-6;
      }
    }
  }

}

#mobile-menu {
  @apply md:hidden border-t-1 px-3 py-3;
  border-color: #e8e8e8;

  ul {


    ul > li > a {
      @apply pl-6;
    }

    ul > li > ul > li > a {
      @apply pl-9;
    }

    @apply flex flex-col space-y-1;

    & > li {

      &.current_ancestor > a {
        color: $color-primary;
      }

      &.current_ancestor > a:hover {
        color: $color-primary !important;
      }

      &.active > a, &.active > a:hover {
        background-color: $color-primary;
        @apply text-white;
      }

      & > a {
        @apply block text-base font-medium;
        @apply relative;

        @apply text-base lg:text-lg xl:text-xl;
        font-family: $navigation-font-family;
        font-weight: $navigation-font-weight;

        @apply flex items-center;

        &:hover {
          background-color: #e8e8e8;
          @apply text-black;
        }

        .navigation-label {
          @apply flex-grow py-2 pl-3;
        }

        .submenu-helper {
          @apply flex-none ml-5 w-10 py-2;

          &:before {
            @apply block text-center;
            content: "\f054";
            //noinspection CssNoGenericFontName
            font-family: "Font Awesome 6 Free";
            font-weight: 700;
          }

        }
      }

    }

  }

  & > ul {

    & > li ul {
      @apply hidden;
    }

  }

  li.opened {
    & > ul {
      @apply block;
    }

    & > a > .submenu-helper:before {
      content: "\f078";
    }

  }

}


.hero {

  @apply bg-center bg-no-repeat;
  background-size: auto 100%;
  background-color: #e8e8e8;

  .page-container {
    @apply flex justify-start items-center py-12 md:py-0 md:h-96 lg:h-136 xl:h-148;
  }

  .hero-content-container {
    @apply w-auto md:w-72 xl:w-108;

    .hero-content {

      .hero-caption {

        font-family: $navigation-font-family;
        font-weight: $font-weight-bold;
        color: $color-primary;
        @apply text-2xl lg:text-3xl xl:text-4xl uppercase;

        .hero-subcaption {
          @apply normal-case text-black;
        }

      }

      .hero-text {
        @apply pt-2 lg:pt-3 xl:pt-4 pb-4 lg:pb-6 xl:pb-8 text-base lg:text-lg xl:text-xl;
        font-weight: $font-weight-light;
      }

    }
  }

}

.home-boxes {

  @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-5 lg:gap-x-7 gap-y-8 lg:gap-y-10 py-10 md:py-18;

  .block-service {
    @apply shadow-2xl border-t-10 relative;

    &.block-service-1 {
      border-color: $service-block-color-1;
    }

    &.block-service-2 {
      border-color: $service-block-color-2;
    }

    &.block-service-3 {
      border-color: $service-block-color-3;
    }

    &.block-service-4 {
      border-color: $service-block-color-4;
    }

    &.block-service-5 {
      border-color: $service-block-color-5;
    }

    &.block-service-6 {
      border-color: $service-block-color-6;
    }

    .block-heading {
      @apply flex gap-x-5 px-5 md:px-7 py-4 md:py-6 border-b-2 items-center;
      border-color: #e0e0e0;

      .block-icon {
        @apply flex-none bg-contain w-10 md:w-14 xl:w-16 h-10 md:h-14 xl:h-16 ;
      }

      .block-title {

        .block-caption {
          @apply text-xl md:text-2xl;
          font-weight: $font-weight-bold;
        }

        .block-subcaption {
          @apply text-base;
          color: #8c8c8c;
          font-weight: $font-weight-light;
        }

      }
    }

    .block-content {
      @apply px-6 md:px-10 pt-6 md:pt-10 pb-14 md:pb-18;

      ul {

        @apply pb-5 pl-2;

        li {
          @apply pl-3 py-1 relative;

          &:before {
            content: "\f054";
            //noinspection CssNoGenericFontName
            font-family: "Font Awesome 6 Free";
            font-weight: 700;
            @apply text-xs pr-5;
            position: absolute;
            top: 7px;
            left: -9px;
          }

        }

      }

      .block-link {
        @apply absolute bottom-8 md:bottom-12;
        color: $color-primary;

        & > i {
          @apply text-sm;
        }
      }
    }


  }

}

.about-us {
  @apply bg-center bg-no-repeat;
  background-size: auto 100%;
  background-color: #e8e8e8;

  .page-container {
    @apply flex justify-end items-center py-12 md:py-0 md:h-96 lg:h-124 xl:h-[566px];
  }

  .about-us-content-container {

    @apply w-auto md:w-92 lg:w-124 xl:w-142;

    .about-us-content {

      @apply text-right;

      .about-us-caption {

        font-family: $navigation-font-family;
        font-weight: $font-weight-bold;

        @apply text-2xl lg:text-3xl xl:text-4xl leading-tight relative;

        strong {
          color: $color-primary;
          @apply uppercase;
        }

        &:after {
          content: "";
          @apply block border-b-4 w-12 absolute right-0 -bottom-2.5;
          border-color: $heading-decoration-color;
        }

      }

      .about-us-text {
        @apply pt-6 lg:pt-8 xl:pt-10 pb-4 lg:pb-8 xl:pb-10 text-base lg:text-lg xl:text-xl;
        font-weight: $font-weight-light;

        p {
          @apply pt-3 lg:pt-6;
          &:first-child {
            @apply pt-0;
          }
        }
      }

    }
  }

}

.home-actualities {

  @apply py-12 lg:py-18;

  h1 {
    font-family: $navigation-font-family;
    font-weight: $font-weight-bold;

    @apply text-2xl lg:text-3xl xl:text-4xl leading-tight relative mb-2.5;

    strong {
      color: $color-primary;
      @apply uppercase;
    }

    &:after {
      content: "";
      @apply block border-b-4 w-12 absolute left-0 -bottom-2.5;
      border-color: $heading-decoration-color;
    }

  }

}

.actuality-boxes {
  @apply grid grid-cols-1 lg:grid-cols-3 gap-y-10 gap-x-7 pt-8 lg:pt-13;

  .block-actuality {

    @apply block sm:flex space-x-4 lg:block;

    .actuality-image {
      @apply flex-none sm:pt-1.5 lg:pt-0 w-auto sm:w-1/3 lg:w-auto;

      img {
        @apply w-full;
      }
    }

    .actuality-content {
      @apply px-3 pt-7 sm:px-0 sm:pt-0 lg:px-3 lg:pt-9 pb-3 md:pb-4;

      .actuality-caption {
        @apply text-lg xl:text-xl overflow-hidden overflow-ellipsis line-clamp-2 sm:line-clamp-1 lg:line-clamp-2;
        font-weight: $font-weight-bold;
      }

      .actuality-time {
        @apply relative text-sm md:text-base pt-1 md:pt-3 pb-0 md:pb-2 mb-0 md:mb-1.5;
        color: #b1b1b1;
        font-weight: $font-weight-light;

        &:after {
          content: "";
          @apply block border-b-3 w-8 absolute left-0 -bottom-3;
          border-color: $heading-decoration-color;
        }
      }

      .actuality-perex {
        @apply pt-6 text-justify overflow-hidden overflow-ellipsis line-clamp-3;
      }
    }

    .actuality-actions {
      @apply text-right;
    }

  }

}

.page-content {

  @apply border-t-1;
  border-color: #e8e8e8;

  @apply py-12 lg:py-18;

  h1 {
    font-family: $navigation-font-family;
    font-weight: $font-weight-bold;

    @apply text-2xl lg:text-3xl xl:text-4xl leading-tight relative mb-9;

    strong {
      color: $color-primary;
      @apply uppercase;
    }

    &:after {
      content: "";
      @apply block border-b-4 w-12 absolute left-0 -bottom-2.5;
      border-color: $heading-decoration-color;
    }

  }

}

footer {
  @apply text-sm lg:text-base;
  background-color: $footer-bg-color;
  color: $footer-text-color;

  .page-container {
    @apply flex flex-col sm:flex-row justify-start items-center space-x-6 lg:space-x-8 sm:h-24 md:h-28 lg:h-32 xl:h-42 py-6 sm:py-0;  ;
  }

  .logo {
    @apply flex-none;
    @apply mb-3 sm:mb-0;
    img {
      @apply w-auto h-8 lg:h-10;
    }
  }

  .divider {
    @apply h-13 border-l-1 hidden sm:block;
    border-color: $footer-divider-color;
  }

  .copyright {
    @apply flex-none;
    .site-name {
      color: $footer-site-color;
    }
  }

  .spacer {
    @apply flex-grow;
  }

  .social-networks {
    @apply flex space-x-3.5;

    .social-network {
      @apply my-3 sm:my-0 inline-block w-11 h-11;

      span {
        @apply hidden;
      }
    }

  }

  .topweby {
    @apply text-right;
  }

  .divider, .copyright, .social-networks .topweby {
    @apply mt-3 sm:mt-5 lg:mt-7;
  }
}

.contact-form-container {
  //@apply pt-10 pb-16 lg:pt-16 lg:pb-20;

  h2 {
    @apply text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl;
  }
}

.pagination-active {
  border-color: $color-primary;
  color: $color-primary;
}

.actuality-detail {
  .actuality-time {
    @apply relative text-sm md:text-base pb-0 md:pb-2 mb-0 md:mb-1.5;
    color: #b1b1b1;
    font-weight: $font-weight-light;
  }

  .actuality-perex {
    font-weight: $font-weight-semibold;
  }

  .actuality-image {
    @apply sm:float-right;

    img {
      @apply sm:max-w-xs mt-2 sm:ml-6 mb-2 ;
    }
  }
}

.form-common {
  @apply mt-12;

  & > .form-content {
    @apply bg-gray-50 px-10 py-10 max-w-2xl mx-auto;
  }

  .form-row {
    @apply mb-6;
  }

  .form-label {
    @apply block text-gray-800;
  }

  .form-control {
    @apply mt-1 w-full;

    &.disabled {
      @apply border-gray-300 text-gray-500;
    }

    &.error {
      @apply border-red-700;
    }
  }

  .form-help {
    @apply mt-1 text-gray-600;
  }

  .form-errors {
    li {
      @apply text-red-700;
    }
  }

  .form-choice-expanded {
    @apply mt-2;

    & > div {
      @apply flex items-center;
    }
  }

  .form-checkbox-row {
    @apply mb-6;

    & > div {
      @apply inline-flex items-center;
    }
  }

  .form-checkbox,
  .form-radio {
    @apply mr-2;
  }

  .form-button-row {
    @apply flex justify-center;
  }
}


